import { graphql } from "gatsby";
import BusinessLoans from "../templates/businessLoans";

const BusinessLoansPage = ({
	data: {
		craft: { entry },
	},
}) => <BusinessLoans entry={entry} />;

export const pageQuery = graphql`
	query BusinessLoansPage {
		craft {
			entry(slug: "business-loans", section: "businessLoans") {
				... on CraftCMS_businessLoans_businessLoans_Entry {
					heroTitle
					heroText
					heroCtaFirst {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					heroImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					heroImageMobile {
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					uniqueFeaturesTitle
					uniqueFeaturesText
					uniqueFeaturesButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					uniqueFeatureBlocks {
						... on CraftCMS_uniqueFeatureBlocks_featureBlock_BlockType {
							id
							featureBlockTitle
							featureBlockText
							featureBlockImage {
								title
								width
								height
								url
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
						}
					}
					stepsRowTitle
					stepsRowText
					stepsRowSteps {
						... on CraftCMS_stepsRowSteps_step_BlockType {
							id
							stepTitle
							stepText
						}
					}
					simpleBannerTitle
					simpleBannerText
					simpleBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					simpleBannerImage {
						title
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					simpleBannerImageMobile {
						title
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					bulletListTitle
					bulletListItems {
						... on CraftCMS_bulletListItems_bulletListItem_BlockType {
							bulletListItemText
						}
					}
					bulletListImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					footerBannerTitle
					footerBannerText
					footerBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;

export default BusinessLoansPage;
